import { useState } from "react";

const useHoverState = (): [
  boolean,
  {
    onMouseEnter: React.MouseEventHandler<any>;
    onMouseLeave: React.MouseEventHandler<any>;
  }
] => {
  const [isHovered, setIsHovered] = useState(false);
  const onMouseEnter = () => {
    setIsHovered(true);
  };
  const onMouseLeave = () => {
    setIsHovered(false);
  };
  return [isHovered, { onMouseEnter, onMouseLeave }];
};

export default useHoverState;
