import React from "react";
import ExpandingBackground from "../Card/ExpandingBackground";

export interface BlogCardProps {
  darkThemeEnabled?: boolean;
  className?: string;
  children: React.ReactNode;
  hoverEffectDisabled?: boolean;
}

const PreviewCard: React.FC<BlogCardProps> = ({
  darkThemeEnabled,
  className,
  hoverEffectDisabled,
  children,
}) => {
  return (
    // note: mobile uses shadow instead of drop-shadow filter,
    //       this solves an iOS performance issue
    <div
      className={`
        ${hoverEffectDisabled ? "" : "group "}
        tablet:shadow-none tablet:drop-shadow-xl relative z-10 col-span-4 flex
        flex-1 flex-col shadow-md
        ${darkThemeEnabled ? "text-white" : "text-primary-black"}
        ${className ?? ""}`}
    >
      <ExpandingBackground darkThemeEnabled={darkThemeEnabled} />
      <div className="relative flex flex-1 cursor-pointer flex-col">{children}</div>
    </div>
  );
};

export default PreviewCard;
