import React from "react";
import styles from "./PageHeaderTwoCircle.module.css";

const PageHeaderTwoCircle: React.FC = () => {
  return (
    <div className="absolute -left-1/4 top-0 aspect-square h-[150%] max-h-[1080px]">
      <div className={styles.circle} />
    </div>
  );
};

export default PageHeaderTwoCircle;
