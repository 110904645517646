import React from "react";

interface ExpandingBackgroundProps {
  darkThemeEnabled?: boolean;
}

const ExpandingBackground: React.FC<ExpandingBackgroundProps> = ({ darkThemeEnabled }) => {
  return (
    <div
      className={`transition-1 absolute z-0 cursor-pointer ${
        darkThemeEnabled ? "bg-primary-black" : "bg-white/70"
      }
        group-hover:inset-x-[-0.3125rem] group-hover:-top-0 group-hover:bottom-[-0.3125rem] ${
          darkThemeEnabled ? "bg-primary-black" : "bg-white/100"
        }
            inset-0`}
    />
  );
};

export default ExpandingBackground;
