import { ActiveDialog, useDialogForms } from "@/components/Layout/useDialogForms";
import {
  AnchorLink,
  BlogOverviewPage,
  BlogPage,
  ContactPage,
  CustomerStoryOverviewPage,
  CustomerStoryPage,
  DialogForm,
  DynamicPage,
  EmployeeStoryOverviewPage,
  EmployeeStoryPage,
  ExternalPage,
  FaqPage,
  RequestAppointmentForm,
  ServicePage,
  ServiceRequestForm,
  ShortLeaseSupplyPage,
  ShortleaseCarDetailsPage,
  StaticPage,
  SupplyPage,
  WhitepaperOverviewPage,
} from "@/services/prepr/types_codegen";
import { FaqSubject, FaqTopic } from "@/services/prepr/queries/getFaqSubjects";
import {
  ServiceCategory,
  ServiceSubCategory,
} from "@/services/prepr/queries/getServiceSubcategories";
import { SpecificAppointmentFormProps } from "@/services/prepr/queries/getSpecificAppointmentForm";
import { DialogFormType } from "@/components/Layout/DialogFormType";

type DynamicPageTarget = Pick<DynamicPage, "__typename" | "_slug">;
type StaticPageTarget = Pick<StaticPage, "__typename" | "_slug">;
type SupplyPageTarget = Pick<SupplyPage, "__typename">;
type ServiceRequestFormTarget = Pick<ServiceRequestForm, "__typename" | "_slug">;
type DialogFormTarget = Pick<DialogForm, "__typename" | "form_type" | "salesforce_entity" | "name">;
type ExternalPageTarget = Pick<ExternalPage, "__typename" | "url">;
type AnchorLinkTarget = Pick<AnchorLink, "__typename" | "link">;
type FaqTopicTarget = Pick<FaqTopic, "name" | "__typename">;
type FaqPageTarget = Pick<FaqPage, "__typename">;
type ServicePageTarget = Pick<ServicePage, "__typename">;
type WhitepaperOverviewPageTarget = Pick<WhitepaperOverviewPage, "__typename">;
type EmployeeStoryOverviewPageTarget = Pick<EmployeeStoryOverviewPage, "__typename">;
type ContactPageTarget = Pick<ContactPage, "__typename">;
type BlogOverviewPageTarget = Pick<BlogOverviewPage, "__typename">;
type BlogPageTarget = Pick<BlogPage, "__typename" | "_slug">;
type CustomerStoryOverviewPageTarget = Pick<CustomerStoryOverviewPage, "__typename">;
type CustomerStoryPageTarget = Pick<CustomerStoryPage, "__typename" | "_slug">;
type EmployeeStoryPageTarget = Pick<EmployeeStoryPage, "__typename" | "_slug">;
type ShortLeaseSupplyPageTarget = Pick<ShortLeaseSupplyPage, "__typename">;
type ShortleaseCarDetailsPageTarget = Pick<ShortleaseCarDetailsPage, "__typename" | "_slug">;

type FaqSubjectTarget = Pick<FaqSubject, "name" | "__typename"> & {
  topic?: FaqTopicTarget[] | null;
};
type ServiceCategoryTarget = Pick<ServiceCategory, "name" | "__typename">;
type RequestAppointmentFormTarget = Pick<
  RequestAppointmentForm,
  "__typename" | "_slug" | "title" | "introtext" | "confirmationtext" | "campaigntag"
>;
type ServiceSubCategoryTarget = Pick<ServiceSubCategory, "name" | "__typename"> & {
  category?: ServiceCategoryTarget[] | null;
};

export type ButtonTarget =
  | DynamicPageTarget
  | StaticPageTarget
  | SupplyPageTarget
  | ServiceRequestFormTarget
  | DialogFormTarget
  | ExternalPageTarget
  | AnchorLinkTarget
  | FaqTopicTarget
  | FaqSubjectTarget
  | ServiceCategoryTarget
  | ServiceSubCategoryTarget
  | RequestAppointmentFormTarget
  | FaqPageTarget
  | ServicePageTarget
  | WhitepaperOverviewPageTarget
  | EmployeeStoryOverviewPageTarget
  | ContactPageTarget
  | BlogOverviewPageTarget
  | BlogPageTarget
  | CustomerStoryOverviewPageTarget
  | CustomerStoryPageTarget
  | EmployeeStoryPageTarget
  | ShortLeaseSupplyPageTarget
  | ShortleaseCarDetailsPageTarget
  | undefined;

interface OpenDialogForm {
  openDialog: (typename: ActiveDialog, name: string) => () => void;
  openSpecificAppointmentForm: (props: SpecificAppointmentFormProps) => void;
}

const getFaqOrServicePath = (
  typename: (
    | FaqTopicTarget
    | FaqSubjectTarget
    | ServiceCategoryTarget
    | ServiceSubCategoryTarget
  )["__typename"]
) => {
  if (!typename) return undefined;
  return "service-contact";
};

const getFaqOrServiceCategoryHref = (buttonTarget: FaqTopicTarget | ServiceCategoryTarget) => {
  const path = getFaqOrServicePath(buttonTarget.__typename);
  const category = buttonTarget.name;
  if (!category) return path;
  return `/${path}?category=${category}`;
};

const getFaqOrServiceSubCategoryHref = (
  buttonTarget: FaqSubjectTarget | ServiceSubCategoryTarget
) => {
  const path = getFaqOrServicePath(buttonTarget.__typename);
  let category;

  if (buttonTarget.__typename === "ServiceSubcategory") {
    category = buttonTarget.category?.[0].name;
  }

  if (buttonTarget.__typename === "FaqSubject") {
    category = buttonTarget.topic?.[0].name;
  }

  const subCategory = buttonTarget.name;
  if (!category || !subCategory) return path;
  return `${path}?category=${category}&subCategory=${subCategory}`;
};

const getHref = (buttonTarget: ButtonTarget) => {
  switch (buttonTarget?.__typename) {
    case "DialogForm":
    case undefined:
      return null;
    case "AnchorLink":
      return buttonTarget.link;
    case "ExternalPage":
      return buttonTarget.url;
    case "FaqTopic":
    case "ServiceCategory":
      return getFaqOrServiceCategoryHref(buttonTarget);
    case "FaqSubject":
    case "ServiceSubcategory":
      return getFaqOrServiceSubCategoryHref(buttonTarget);
    case "SupplyPage":
      return "/autolease/aanbod";
    case "ShortLeaseSupplyPage":
      return "/autolease/aanbod/shortlease";
    case "BlogOverviewPage":
      return "/blog";
    case "ContactPage":
      return "/service-contact";
    case "FaqPage":
      return "/service-contact";
    case "ServicePage":
      return "/service";
    case "WhitepaperOverviewPage":
      return "/whitepapers";
    case "CustomerStoryOverviewPage":
      return "/klantverhalen";
    case "EmployeeStoryOverviewPage":
      return "/medewerkerverhalen";
    default:
      return buttonTarget?._slug;
  }
};

const getType = (buttonTarget: ButtonTarget) => {
  if (
    ["DialogForm", "AnchorLink", "RequestAppointmentForm"].includes(buttonTarget?.__typename ?? "")
  )
    return "button";
  if (buttonTarget?.__typename === "ExternalPage") return "externalLink";
  return "link";
};

const scrollToAnchor = (buttonTarget: AnchorLinkTarget) => () => {
  buttonTarget?.link &&
    document.querySelector(buttonTarget.link)?.scrollIntoView({
      behavior: "smooth",
    });
};

const getButtonCallback = (
  buttonTarget: ButtonTarget,
  { openDialog, openSpecificAppointmentForm }: OpenDialogForm
) => {
  if (buttonTarget?.__typename === "AnchorLink") {
    return scrollToAnchor(buttonTarget);
  }
  if (buttonTarget?.__typename === "DialogForm") {
    switch (buttonTarget.form_type as DialogFormType) {
      case "Contact":
        return buttonTarget.salesforce_entity === "case"
          ? openDialog("case-contact", buttonTarget.name ?? "")
          : openDialog("lead-contact", buttonTarget.name ?? "");
      case "CallMeBack":
        return buttonTarget.salesforce_entity === "case"
          ? openDialog("case-callMeBack", buttonTarget.name ?? "")
          : openDialog("lead-callMeBack", buttonTarget.name ?? "");
      case "RequestAppointment":
        return buttonTarget.salesforce_entity === "case"
          ? openDialog("case-requestAppointment", buttonTarget.name ?? "")
          : openDialog("lead-requestAppointment", buttonTarget.name ?? "");
      case "Recruitment":
        return buttonTarget.salesforce_entity === "case"
          ? openDialog("case-recruitment", buttonTarget.name ?? "")
          : openDialog("lead-recruitment", buttonTarget.name ?? "");
      case "InventoryFinancingContact":
        return buttonTarget.salesforce_entity === "lead"
          ? openDialog("inventoryFinancingContact", buttonTarget.name ?? "")
          : openDialog("inventoryFinancingContact", buttonTarget.name ?? ""); // There is only a lead form for this
      default:
        return undefined;
    }
  }
  if (buttonTarget?.__typename == "RequestAppointmentForm") {
    return () => openSpecificAppointmentForm(buttonTarget);
  }
  return undefined;
};

export const useButtonTarget = (
  buttonTarget: ButtonTarget
): {
  href?: string | null;
  onClick?: () => void;
  type: "link" | "button" | "externalLink";
  isValid: boolean;
} => {
  const dialogForms = useDialogForms();
  return unpackButtonTarget(buttonTarget, dialogForms);
};

export const unpackButtonTarget = (
  buttonTarget: ButtonTarget,
  dialogForms: ReturnType<typeof useDialogForms>
): {
  href?: string | null;
  onClick?: () => void;
  type: "link" | "button" | "externalLink";
  isValid: boolean;
} => {
  const onClick = getButtonCallback(buttonTarget, dialogForms);
  const href = getHref(buttonTarget);
  const type = getType(buttonTarget) ?? "button";
  return { href, onClick, type, isValid: type === "button" ? !!onClick : !!href };
};
