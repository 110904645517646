import React from "react";
import ImageWithFallback from "@/components/shared/ImageWithFallback";

interface CardImageProps {
  imageUri?: string | null;
  imageDescription?: string | null;
  labelText?: string;
  labelColor?: "black" | "aubergine";
}

const CardImage: React.FC<CardImageProps> = ({
  imageUri,
  imageDescription,
  labelText,
  labelColor,
}) => {
  const color = labelColor === "black" ? "bg-primary-black" : "bg-secondary-aubergine";
  return (
    <div className="relative h-[14.625rem] w-full">
      <div
        className={`transition-1 absolute inset-x-[-0.3125rem] -top-0 bottom-0 mx-[.3125rem] group-hover:mx-0`}
      >
        {imageUri && (
          <ImageWithFallback
            src={imageUri}
            fallbackSrc="/icons/placeholder.svg"
            layout="fill"
            alt={imageDescription || "card image"}
            objectFit="cover"
          />
        )}
        {labelText && (
          <div className="absolute right-0 top-0 px-6 py-4">
            <div className={`${color} px-4 py-2`}>
              <p className="text-subline-sm text-white">{labelText}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardImage;
